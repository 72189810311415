import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import Image from 'next/image'
import dayjs from 'dayjs'
import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Icon } from '@iconify/react'
import { Progress, Space, Anchor, Row, Col, Button, Drawer, Radio } from 'antd'
import styles2 from 'styles/comparison-and-review.module.scss'
import Comment from 'src/components/Blog/comment'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Top from 'src/components/Blog/top'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'next-i18next'

//底部标签
const Label = [
  {
    name: 'catch-cheaters',
    data: [
      {
        title: 'Relationships',
        href: '/blog/catch-cheaters'
      }
    ]
  },
  {
    name: 'parental-control',
    data: [
      {
        title: 'Parental Control',
        href: '/blog/parental-control'
      }
    ]
  },
  {
    name: 'step-guide',
    data: [
      {
        title: 'Step to step guide',
        href: '/blog/step-guide'
      }
    ]
  },
  {
    name: 'track-android',
    data: [
      {
        title: 'Track Android',
        href: '/blog/track-android'
      }
    ]
  },
  {
    name: 'track-iphone',
    data: [
      {
        title: 'Track iPhone',
        href: '/blog/track-iphone'
      }
    ]
  }
]

const right = '>'

function Slug(data) {
  const [Data, setData] = useState(null)

  const router = useRouter()

  const [DATA, setDATA] = useState([])

  const { query } = useRouter()

  const [State, setState] = useState(false)

  const [leftNavs, setleftNavs] = useState(false)

  const contentRef = useRef(null) //

  const { i18n } = useTranslation()

  useEffect(() => {
    if (
      window.location.pathname.slice(-2) === 'jp' ||
      window.location.pathname.slice(-2) === 'de' ||
      window.location.pathname.slice(-2) === 'kr' ||
      window.location.pathname.slice(-2) === 'fr' ||
      window.location.pathname.slice(-2) === 'es' ||
      window.location.pathname.slice(-2) === 'it' ||
      window.location.pathname.slice(-2) === 'pt'
    ) {
      let locale

      if (window.location.pathname.slice(-2) === 'jp') {
        locale = 'ja-JP'
      } else if (window.location.pathname.slice(-2) === 'kr') {
        locale = 'ko-KR'
      } else {
        locale = window.location.pathname.slice(-2)
      }

      const blog = async () => {
        const client = new ApolloClient({
          uri: `${process.env.NEXT_PUBLIC_BASE_GQL_URL}`,
          cache: new InMemoryCache()
        })

        const res = await client.query({
          query: gql`
        query {
          tsafelyArticles(
            filters: {
              slug: { eq:"${window.location.pathname.substring(1)}"}
            }
            locale: "${locale}"
          ) {
            data {
              id
              attributes {
                title
                content
                updatedAt
                subtitle
                slug
                seo {
                metaTitle
                metaDescription
                keywords
              }
              style
              li_meta
                tsafely_article_author {
                  data {
                    id
                    attributes {
                      name
                      slug
                      profile_photo {
                        data {
                          id
                          attributes {
                            name
                            url
                          }
                        }
                      }
                    }
                  }
                }
                tsafely_category {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                image {
                  data {
                    id
                    attributes {
                      name
                      url
                    }
                  }
                }
              }
            }
          }
        }`
        })

        setData(res.data.tsafelyArticles.data[0])
      }
      blog()
    } else {
      const a = async () => {
        const client = new ApolloClient({
          uri: `${process.env.NEXT_PUBLIC_BASE_GQL_URL}`,
          cache: new InMemoryCache()
        })

        const res = await client.query({
          query: gql`
            query {
              tsafelyArticles(
                filters: {
                  slug: { eq:"${window.location.pathname.substring(1)}"}
                }
              ) {
                data {
                  id
                  attributes {
                    title
                    content
                    updatedAt
                    subtitle
                    slug
                    seo {
                    metaTitle
                    metaDescription
                    keywords
                  }
                  style
                  li_meta
                    tsafely_article_author {
                      data {
                        id
                        attributes {
                          name
                          slug
                          profile_photo {
                            data {
                              id
                              attributes {
                                name
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                    tsafely_category {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    image {
                      data {
                        id
                        attributes {
                          name
                          url
                        }
                      }
                    }
                  }
                }
              }
            }`
        })

        setData(res.data.tsafelyArticles.data[0])
      }
      a()
    }
  }, [query])

  //回到顶部按钮
  const [scrolled280px, setScrolled280px] = useState(false)

  const [scroll, setScroll] = useState('')

  const handleScroll2 = () => {
    // 获取当前滚动的垂直距离
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    // 判断滚动距离是否大于等于50px
    if (scrollTop >= 210 && !scrolled280px) {
      setScrolled280px(true)
    } else if (scrollTop < 210 && scrolled280px) {
      setScrolled280px(false)
    }

    const container = document.getElementById('containerElement')

    if (container !== null) {
      setScroll(container.getBoundingClientRect().bottom)
    }
  }

  useEffect(() => {
    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll2)

    // 在组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll2)
    }
  }, [scrolled280px])

  /* 左侧数据 */
  const [anchorList, setAnchorList] = useState([])

  const [anchorList2, setAnchorList2] = useState([])

  /* 嵌入锚点 */
  useEffect(() => {
    const container = document.getElementById('containerElement')

    if (container) {
      const headings = container.querySelectorAll('h2, h3')

      let data = []

      let data2 = []

      let index

      for (let i = 0; i < headings.length; i++) {
        data.push({
          title: headings[i].textContent.trim(),
          biggest: headings[i].tagName.toLowerCase(), // 获取标签名，可以是"h2"或"h3"
          key: i,
          href: `#id${i}`
        })

        if (headings[i].tagName.toLowerCase() === 'h2') {
          data2.push({
            title: headings[i].textContent.trim(),
            biggest: headings[i].tagName.toLowerCase(), // 获取标签名，可以是"h2"或"h3"
            key: i,
            href: `#id${i}`,
            data: []
          })
          index = i
        } else {
          data2[data2.length - 1].data.push({
            title: headings[i].textContent.trim(),
            biggest: headings[i].tagName.toLowerCase(), // 获取标签名，可以是"h2"或"h3"
            key: i,
            href: `#id${index + 1}`
          })
          index = i
        }
      }

      headings.forEach((element, index) => {
        element.setAttribute('id', data[index].href.substring(1))
      })

      setAnchorList(data2)
      setAnchorList2(data)
    }
  }, [query, Data])
  const [open3, setOpen3] = useState(false)

  const onClose = () => {
    setOpen3(!open3)
    setleftNavs(!leftNavs)
  }

  const [queryId, setQueryId] = useState(2)

  useEffect(() => {
    if (queryId % 2 === 0 && window.location.hash) {
      setTimeout(() => {
        const currentScrollY = window.scrollY // 获取当前垂直滚动位置
        const targetScrollY = currentScrollY - 100 // 在当前位置的基础上向上移动80像素

        window.scrollTo({
          top: targetScrollY,
          behavior: 'smooth' // 使用平滑滚动效果
        })
        setQueryId(Math.floor(Math.random() * 450) * 2 + 101)
      }, [200])
    }
  }, [queryId, query])

  useEffect(() => {
    // 获取所有<a>标签
    const anchorElements = document.querySelectorAll('a')

    // 为每个<a>标签添加点击事件监听器
    anchorElements.forEach(anchor => {
      anchor.addEventListener('click', handleClick)
    })

    // 在组件卸载时移除事件监听器
    return () => {
      anchorElements.forEach(anchor => {
        anchor.removeEventListener('click', handleClick)
      })
    }
  }, [])

  const handleClick = event => {
    if (event.target.href === undefined) {
      setQueryId(Math.floor(Math.random() * 450) * 2 + 100)
    }
  }

  const [Bopen, setBopen] = useState(false)

  //投票框
  const [columns1, setColumns1] = useState([])

  const [VoteId, setVoteId] = useState(null)

  const [vote, setVote] = useState(false)

  //投票
  const VoteSubmit = async value => {
    setBopen(true)

    const response = await fetch(`https://strapi.msafely.com/api/vote`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: 1,
        value: value,
        email: ''
      })
    })

    const res = await response.json()
    if (res.code === 0) {
      setBopen(false)
      setColumns1(res.data.arr)
      localStorage.setItem('vote', true)
      setVote(true)
    }
    setBopen(false)
  }

  const [VoteOpen, setVoteOpen] = useState(false)

  const [commentData, setCommentData] = useState([])

  //投票
  /* const Vote = useMemo(() => {
    return (
      VoteOpen && (
        <div className='fixed bottom-[30%] w-[296px] left-[45%] Fsm:bottom-[27%] Fsm:left-[11.5%] p-6 rounded-md shadow-4xl bg-[#fff] z-40'>
          <p className='text-[14px] font-[600] pb-4 text-center'>{columns1[0].title}(Multiple-Choice)</p>

          {!vote &&
            columns1.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    VoteSubmit(item.vaule), setVoteId(item.vaule)
                  }}
                  className='h-[50px] px-4 w-full bg-[#e0e0e0b7] rounded-md flex items-center justify-between mt-2'
                >
                  <span>{item.label}</span>
                  <input type='checkbox' defaultChecked={item.vaule === VoteId ? true : false} className='' />
                </button>
              )
            })}

          <button
            className='absolute rounded-full bg-white -top-2 -right-2 shadow-3xl'
            onClick={() => {
              setVoteOpen(false), setState(false), setleftNavs(false)
            }}
          >
            <Icon icon='material-symbols:close-rounded' className='w-[22px] h-[22px]' />
          </button>

          <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={Bopen}>
            <CircularProgress color='inherit' />
          </Backdrop>

          {vote && (
            <>
              <Space direction='vertical'>
                {columns1.map((item, index) => {
                  let ratio = item.ratio.replace(/%/g, '')

                  return (
                    <div key={index} className='flex relative'>
                      <Progress percent={ratio} className='' />
                      <p className=' absolute top-[22%] left-[6%] text-black text-opacity-90'>{item.label}</p>
                      <p className=' absolute top-[22%] right-[10%] text-black text-opacity-90'>{item.ratio}</p>
                    </div>
                  )
                })}
              </Space>
              <p className='text-center mt-1 text-[15px]'>Thanks for your feedback!</p>
            </>
          )}
        </div>
      )
    )
  }, [columns1, VoteOpen, vote, VoteId, Bopen]) */

  /* 头部 */
  const Cheader = Data !== null && Data !== undefined && (
    <>
      {/*  <div className='max-w-[1280px] text-[#535460] h-full w-full mx-auto pt-4 Fsm:pt-2 pl-4 Fsm:text-[12px] text-[13px] flex items-center'>
        <Link href={'/blog'}>Blog</Link>
        <div className='mx-1 mt-[2px]'>{right}</div>
      </div> */}
      <div className=' mx-auto Blds:pb-[25px] pb-[40px] Fsm:pb-[10px] pt-[30px] Fsm:pt-[20px] border-b-[1px]'>
        <h1 className='text-[#333] text-[50px] font-[800] text-center leading-[70px] Logosm:leading-[40px] Hsm:text-[34px] Hsm:Logosm:text-[26px] w-[48%] Headers3:w-[80%] Headers3:Hsm:w-[96%] mx-auto'>
          {Data.attributes.title}
        </h1>
        {/* <div className='flex items-center pt-[50px] sm:pt-[25px] sm:pb-[10px] pb-[20px] mx-auto justify-center'>
          <Link
            href={`/author/detail/${Data.attributes.tsafely_article_author.data.attributes.slug}`}
            className='w-[43px] h-[42px] rounded-full overflow-hidden border-blue-200 border-[2px] '
          >
            <Image
              src={Data.attributes.tsafely_article_author.data.attributes.profile_photo.data.attributes.url}
              width={100}
              height={100}
              className=''
              alt='photo'
            />
          </Link>
          <div className='ml-[20px] flex items-center'>
            <Link
              href={`/author/detail/${Data.attributes.tsafely_article_author.data.attributes.slug}`}
              className='text-[#535460] text-[13px]'
            >
              {Data.attributes.tsafely_article_author.data.attributes.name}
            </Link>
            <time className='block text-[#535460] text-[13px] ml-8'>
              Last updated:{dayjs(Data.attributes.custom_time).format('MMM DD, YYYY')}
            </time>
          </div>
        </div> */}
      </div>
    </>
  )

  const [expanded, setExpanded] = useState(0)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  /* pc锚点 */
  const leftAnchor = (
    <>
      <Row>
        <Col span={8}>
          {anchorList.map((item, index) => {
            if (item.data.length !== 0) {
              return (
                <Accordion
                  key={index}
                  className='bg-[#fff]'
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} className='p-0'>
                    <Anchor
                      onClick={() => {
                        setQueryId(1)
                      }}
                      key={index}
                      items={[item]}
                      className='font-[600] track-iphone-title'
                    />
                  </AccordionSummary>

                  {item.data.map((item2, index2) => {
                    return (
                      <AccordionDetails key={index2}>
                        <Anchor
                          onClick={() => {
                            setQueryId(1)
                          }}
                          key={index2}
                          items={[item2]}
                          className='track-iphone-title2'
                        />
                      </AccordionDetails>
                    )
                  })}
                </Accordion>
              )
            } else {
              return (
                <Anchor
                  onClick={() => {
                    setQueryId(1)
                  }}
                  key={index}
                  items={[item]}
                  className='font-[600] track-iphone-title'
                />
              )
            }
          })}
        </Col>
      </Row>
    </>
  )

  /* 移动端锚点 */
  const leftAnchor2 = (
    <>
      <Drawer
        placement='bottom'
        closable={false}
        onClose={onClose}
        open={open3}
        key='bottom'
        className='bg-[#012133] Slug h-[480px] overflow-auto relative pb-[75px]'
      >
        <Row className='h-[400px] overflow-auto bg-[#012133]'>
          <Col className='h-full overflow-auto'>
            {anchorList2.map((item, index) => {
              return item.biggest === 'h2' ? (
                <Anchor
                  onClick={() => {
                    setQueryId(1), setleftNavs(!leftNavs), setOpen3(!open3)
                  }}
                  key={index}
                  targetOffset={110}
                  items={[item]}
                  className='font-[600] track-iphone-title'
                />
              ) : (
                <Anchor
                  onClick={() => {
                    setQueryId(1), setleftNavs(!leftNavs), setOpen3(!open3)
                  }}
                  key={index}
                  targetOffset={110}
                  items={[item]}
                  className='track-iphone-title2'
                />
              )
            })}
          </Col>
        </Row>
        <div className=' h-[80px] w-full flex items-center mx-auto justify-center py-4 bg-[#012133] fixed bottom-0 border-t-[1px] border-white/20'>
          <button
            onClick={() => {
              setleftNavs(!leftNavs), setOpen3(!open3)
            }}
            className='w-[170px] h-[38px] bg-[#00a8ff] rounded-md text-white text-[15px]'
          >
            Table of contents
          </button>
        </div>
      </Drawer>
    </>
  )

  useEffect(() => {
    // 获取包含所有子元素的父元素
    var featureElement = document.querySelector('.Guide')

    if (featureElement) {
      // 获取所有子元素
      var childElements = featureElement.querySelectorAll('.MuiPaper-root')

      // 遍历子元素并移除 class
      childElements.forEach(function (childElement) {
        childElement.classList.remove(
          'MuiButtonBase-root',
          'MuiAccordionSummary-root',
          'Mui-expanded',
          'MuiAccordionSummary-gutters',
          'css-1njo7bb-MuiButtonBase-root-MuiAccordionSummary-root',
          'MuiPaper-root',
          'MuiPaper-elevation',
          'MuiPaper-rounded',
          'MuiPaper-elevation1',
          'MuiAccordion-root',
          'MuiAccordion-rounded',
          'MuiAccordion-gutters',
          'pt-2',
          'pb-2',
          'css-ykxb8f',
          'bg-[#f5fafe]',
          'css-1s5gu51',
          'css-4qujed-MuiPaper-root-MuiAccordion-root'
        )
      })
    }
  }, [query, expanded])

  /* 文章部分 */
  const Content = Data !== null && Data !== undefined && (
    <div className='bg-[#fff] w-full relative z-30 Blog pb-[50px]'>
      <div className='bg-[#fff]'>
        {Cheader}

        <div className='max-w-[1400px] mx-auto Blds:pb-[25px] pb-[40px] flex justify-center relative'>
          {/* 左侧 */}
          <div
            className={` border-r-[2px] border-[#12D8AB] w-[18%] h-full Hsm:hidden mt-[60px] ${
              scrolled280px && 'sticky top-[0px] left-[8%] Headers5:left-[2%] Headers5:w-[20%]'
            } `}
          >
            <p className='text-[16px] font-[600] text-center py-4 pb-1'>Table of content</p>

            {leftAnchor}
          </div>

          {/* 文章内容 */}
          <div className=' w-[70%] mx-auto Headers3:w-[52%] Headers3:Hsm:w-[96%] Hmd:mx-10 Hsm:mx-auto pt-[20px] Mlg:pt-[20px]'>
            {/* 文章主内容 */}
            <div className='Guide' id='containerElement'>
              <Col className={styles2.header} dangerouslySetInnerHTML={{ __html: Data.attributes.content }} />
              <div className='w-full h-[1px] bg-black/10 mt-[30px]'></div>
              {Label.map((item, index) => {
                if (item.name === Data.attributes.tsafely_category.data.attributes.name) {
                  return item.data.map((item2, index2) => {
                    return (
                      <Link
                        href={item2.href}
                        key={index2}
                        className='flex items-center text-[#fff] text-[14px] font-[600] mt-[20px]'
                      >
                        <div className='bg-[#4945ff] px-2.5 py-1.5 rounded-md'>{item2.title}</div>
                      </Link>
                    )
                  })
                }
              })}

              {/* <Comment Data={data.data.data[0]} /> */}
            </div>
          </div>
        </div>
      </div>

      <div>
        {!State && !leftNavs && (
          <div className='z-30 relative Hmd:hidden'>
            <div className='fixed bg-[#012133] w-full py-4 bottom-0'>
              <div className='w-[85%] Hsm:w-[95%] h-full flex items-center mx-auto justify-center'>
                <button
                  onClick={() => {
                    setleftNavs(!leftNavs), setOpen3(!open3)
                  }}
                  className='w-[170px] h-[38px] bg-[#00a8ff] rounded-md text-white text-[15px]'
                >
                  Table of contents
                </button>
              </div>
            </div>
          </div>
        )}

        {leftNavs && leftAnchor2}
      </div>
    </div>
  )

  useEffect(() => {
    const handleMouseClick = () => {
      let a = location.search.split('&')
      if (a[1] == 'id=details') {
        let scrollElement = document.getElementById('details')
        if (scrollElement) {
          scrollElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
      if (a[0] == '?open=true') {
        document.getElementById('details').open = true
      }

      if (document.getElementById('method1')) {
        document.getElementById('method1').addEventListener('click', function () {
          let check2 = document.getElementById('method1-content').style.display || 'none'
          document.getElementById('method1-content').style.display = check2 == 'none' ? 'block' : 'none'
        })
      }

      if (document.getElementById('method2')) {
        document.getElementById('method2').addEventListener('click', function () {
          let check2 = document.getElementById('method2-content').style.display || 'none'
          document.getElementById('method2-content').style.display = check2 == 'none' ? 'block' : 'none'
        })
      }
    }

    document.addEventListener('click', handleMouseClick)

    // 在组件卸载时，删除鼠标点击事件监听器
    return () => {
      document.removeEventListener('click', handleMouseClick)
    }
  }, [query])

  return (
    <>
      {Data !== null && Data !== undefined && (
        <>
          <Head>
            <title>{Data.attributes.seo[0].metaTitle}</title>
            <meta name='description' content={Data.attributes.seo[0].metaDescription} />
            <meta name='keywords' content={Data.attributes.seo[0].keywords} />
          </Head>
          {Content}

          {/* 回到顶部按钮 */}
          <Top />
        </>
      )}
    </>
  )
}

export default Slug
