import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'

function Top() {
  //回到顶部按钮
  const [scrolled50px, setScrolled50px] = useState(false)

  const handleScroll = () => {
    // 获取当前滚动的垂直距离
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    // 判断滚动距离是否大于等于50px
    if (scrollTop >= 50 && !scrolled50px) {
      setScrolled50px(true)
    } else if (scrollTop < 50 && scrolled50px) {
      setScrolled50px(false)
    }
  }

  useEffect(() => {
    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll)

    // 在组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrolled50px])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 使用平滑滚动效果
      inline: 'start'
    })
  }

  return (
    <>
      {/* 回到顶部按钮 */}
      {scrolled50px && (
        <button
          onClick={() => scrollToTop()}
          className='border-[1px] fixed bg-[#12D8AB] w-[36px] h-[36px] rounded-full right-[30px] bottom-[30px] flex items-center justify-center Hsm:right-[15px] z-40'
        >
          <Icon icon='ep:top' className='w-[26px] h-[26px] text-white -ml-[2px]' />
        </button>
      )}
    </>
  )
}

export default Top
